/*!
Author: Antenna
Author URI: https://weareantenna.be/
Version: 1.0.0
*/
import "./../scss/app.scss";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const getOffsetTop = (el) => {
  let offsetTop = 0;
  while (el) {
    offsetTop += el.offsetTop;
    el = el.offsetParent;
  }

  return offsetTop;
};

/**
 * Navigation
 */

const navbar = document.querySelector("[data-navbar]");
const navbarToggle = document.querySelectorAll("[data-nav-toggle]");
const scrollTopButton = document.querySelector("[data-scrolltop-button]");
const headerToolboxTrigger = document.querySelector("[data-toolbox-trigger]");

const onScroll = () => {
  const scrollTop =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0;

  // Set navigation class
  if (scrollTop >= 100) {
    navbar.classList.add("scrolling");
  } else {
    navbar.classList.remove("scrolling");
  }

  if (scrollTop >= window.outerHeight) {
    scrollTopButton.classList.add("show");
  } else {
    scrollTopButton.classList.remove("show");
  }
};

window.addEventListener("scroll", onScroll);
onScroll();

navbarToggle.forEach((button) => {
  button.addEventListener("click", (e) => {
    e.preventDefault();
    if (!navbar.classList.contains("open")) {
      navbar.classList.add("open");
      document.body.style.overflow = "hidden";
      headerToolboxTrigger.setAttribute("aria-hidden", "true");
    } else {
      navbar.classList.remove("open");
      document.body.style.overflow = "";
      headerToolboxTrigger.setAttribute("aria-hidden", "false");
    }
  });
});

scrollTopButton.addEventListener("click", (e) => {
  e.preventDefault();
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
});

/**
 * Scrolling to elements
 */

const scrollToTarget = (targetEl) => {
  if (targetEl) {
    window.scrollTo({
      top: getOffsetTop(targetEl) - 50,
      behavior: "smooth",
    });

    setTimeout(function () {
      targetEl.querySelector("[data-focus]").focus();
    }, 500);
  }
};

if (window.location.hash.length) {
  let targetEl = document.querySelector(
    '[data-id="' + window.location.hash.substring(1) + '"]'
  );
  scrollToTarget(targetEl);
}

const scrollToEls = document.querySelectorAll("[data-scroll]");
scrollToEls.forEach((button) => {
  button.addEventListener("click", (e) => {
    e.preventDefault();
    const hash = e.currentTarget.href.split("#").pop();
    const targetEl = document.getElementById(hash);

    if (targetEl) {
      if (e.target.dataset.scroll == "abc") {
        window.scrollTo({
          top: getOffsetTop(targetEl) - 130,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: getOffsetTop(targetEl) - 50,
          behavior: "smooth",
        });
      }

      if (history.pushState) {
        history.pushState(null, null, "#" + hash);
      } else {
        location.hash = "#" + hash;
      }

      setTimeout(function () {
        if (targetEl.querySelector("[data-focus]"))
          targetEl.querySelector("[data-focus]").focus();
      }, 500);
    }
  });
});

const scrollToHeader = document.querySelectorAll("[data-scrollto]");
scrollToHeader.forEach((button) => {
  button.addEventListener("click", (e) => {
    e.preventDefault();
    const hash = e.currentTarget.href.split("#").pop();
    const targetEl = document.getElementById(hash);

    if (targetEl) {
      window.scrollTo({
        top: getOffsetTop(targetEl) - 130,
        behavior: "smooth",
      });
    }
  });
});

/**
 * Gallery block swiper
 */

const gallerySwipers = document.querySelectorAll("[data-gallery-swiper]");
if (gallerySwipers) {
  gallerySwipers.forEach((gallerySwiper) => {
    new Swiper(gallerySwiper, {
      speed: 200,
      loop: true,
      centeredSlides: true,
      slidesPerView: 1,
      spaceBetween: 0,
      autoHeight: false,
      pagination: {
        el: ".swiper-dots",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  });
}

/**
 * Mobile swipers
 */

const articlesSwiper = document.querySelectorAll("[data-articles-swiper]");
let swiperActive = false;
let swipers = [];
const mobileArticleSwiper = () => {
  if (window.innerWidth < 768 && !swiperActive) {
    swiperActive = true;

    articlesSwiper.forEach((articlesWrapper) => {
      articlesWrapper.classList.add("swiper");
      articlesWrapper
        .querySelector("[data-swiper-wrapper]")
        .classList.add("swiper-wrapper");
      articlesWrapper
        .querySelectorAll("[data-swiper-slide]")
        .forEach((slide) => {
          slide.classList.add("swiper-slide");
        });

      let swiper = new Swiper(articlesWrapper, {
        speed: 200,
        loop: true,
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 0,
        autoHeight: true,
        pagination: {
          el: ".swiper-dots",
          clickable: true,
        },
      });

      swipers.push(swiper);
    });
  } else if (window.innerWidth >= 768 && swiperActive) {
    swiperActive = false;

    articlesSwiper.forEach((articlesWrapper) => {
      articlesWrapper.classList.remove("swiper");
      articlesWrapper
        .querySelector("[data-swiper-wrapper]")
        .classList.remove("swiper-wrapper");
      articlesWrapper
        .querySelectorAll("[data-swiper-slide]")
        .forEach((slide) => {
          slide.classList.remove("swiper-slide");
        });

      swipers.forEach((swiper) => {
        swiper.destroy();
      });
    });
  }
};

if (articlesSwiper.length) {
  window.onresize = mobileArticleSwiper;
  mobileArticleSwiper();
}

/**
 * Category filter
 */

const categoryButton = document.querySelectorAll("[data-toptag]");
const parentButton = document.querySelectorAll("[data-parenttag]");
const categoryCards = document.querySelectorAll("[data-cats]");
const activeArr = [];

function toggleFilter() {
  categoryCards.forEach((card) => {
    card.classList.remove("card--hide");
    var cardData = card.dataset.cats;
    var cardArr = cardData.split(" ");

    if (activeArr.length == 0) {
      card.classList.remove("card--hide");
      return;
    }

    if (!cardArr.some((r) => activeArr.includes(r))) {
      card.classList.add("card--hide");
    }
  });
}

categoryButton.forEach((button) => {
  button.addEventListener("click", (e) => {
    e.preventDefault();
    var buttonData = button.dataset.toptag;

    if (button.classList.contains("tag-active")) {
      button.classList.remove("tag-active");

      var index = activeArr.indexOf(button.dataset.toptag);
      activeArr.splice(index, 1);
      toggleFilter();
      return;
    }

    if (button.dataset.parenttag == undefined) {
      parentButton.forEach((parBtn) => {
        if (parBtn.dataset.parenttag == button.dataset.toptag) {
          activeArr.push(parBtn.dataset.toptag);
          parBtn.classList.add("tag-active");
        }
      });
    }

    activeArr.push(buttonData);

    categoryButton.forEach((catBtn) => {
      if (activeArr.includes(catBtn.dataset.toptag)) {
        catBtn.classList.add("tag-active");
        toggleFilter();
        return;
      }
    });
  });
});

/**
 * Collapsable UL (browse)
 */
const amount = 10;
const expandableLists = document.querySelectorAll(".results-list.expandable");
expandableLists.forEach((list) => {
  const listItems = list.querySelectorAll("li");
  if (listItems.length <= amount) {
    list.querySelector(".read-more").classList.add("hidden");
    return;
  }

  listItems.forEach((item, index) => {
    if (index > amount && !item.classList.contains("read-more")) {
      item.classList.add("hidden");
    }
  });

  list.querySelector(".read-more").addEventListener("click", (e) => {
    e.preventDefault();
    listItems.forEach((item) => {
      item.classList.remove("hidden");
    });
    list.querySelector(".read-more").classList.add("hidden");
  });
});

/**
 * Password change
 */

const toggleButton = document.getElementById("password-show");
const toggleButtonCont = document.getElementById("password-show-cont");
const passwordFieldNew = document.getElementById("password-field-new");
const passwordFieldCur = document.getElementById("password-field-current");

if (toggleButton) {
  toggleButton.addEventListener("click", (e) => {
    e.preventDefault();
    toggleButtonCont.classList.add("password--hide");
    passwordFieldNew.classList.remove("password--hide");
    passwordFieldCur.classList.remove("password--hide");
  });
}

/**
 * Help icon
 */

function helpInitialize(helpComp, wrapper) {
  let ariaLabel = "";
  let explanation = "";

  const inputContainer = helpComp.parentNode;
  if (wrapper) {
    if (helpComp.dataset.arialabel) {
      ariaLabel = helpComp.dataset.arialabel;
    }
    if (helpComp.dataset.explanation) {
      explanation = helpComp.dataset.explanation;
      helpComp.innerHTML += `<i class="help-icon" aria-label="${ariaLabel}">?</i><div class="help-bubble"><p>${explanation}</p></div>`;
    }
  } else {
    const instructions =
      inputContainer.parentNode.querySelector(".fui-instructions");
    if (instructions) {
      const p = instructions.querySelector("p");
      if (!p) {
        return;
      }

      let explanation = p.textContent;
      instructions.remove();
      inputContainer.innerHTML += `<div class="help"><i class="help-icon" aria-label="${ariaLabel}">?</i><div class="help-bubble"><p>${explanation}</p></div></div>`;
    }
  }

  inputContainer.classList.add("has-help-icon");
}

const observerConfig = { subtree: true, childList: true };
const observer = new MutationObserver((mutationList, observer) => {
  observer.disconnect();
  for (let mutation of mutationList) {
    if (mutation.type === "childList") {
      document.querySelectorAll(".help.uninitialized").forEach((comp) => {
        comp.classList.remove("uninitialized");
        helpInitialize(comp, true);
      });
    }
  }
  observer.observe(document, observerConfig);
});

observer.observe(document, observerConfig);

document.querySelectorAll(".help.uninitialized").forEach((helpComp) => {
  helpComp.classList.remove("uninitialized");
  helpInitialize(helpComp, true);
});

document.querySelectorAll(".fui-input").forEach((helpComp) => {
  helpInitialize(helpComp, false);
});

const tx = document.getElementsByTagName("textarea");
for (let i = 0; i < tx.length; i++) {
  tx[i].setAttribute(
    "style",
    "height:" + tx[i].scrollHeight + "px;overflow-y:hidden;"
  );
  tx[i].addEventListener("input", OnInput, false);
}

function OnInput() {
  this.style.height = 0;
  this.style.height = this.scrollHeight + "px";
}

/**
 * Scrollto
 */
const scrolltoBtns = document.querySelectorAll("[data-scrollto]");
scrolltoBtns.forEach(function (btn) {
  btn.addEventListener("click", function (e) {
    e.preventDefault();
    var element = document.querySelector(
      `#${btn.getAttribute("data-scrollto")}`
    );
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  });
});

/**
 * Toolbox
 */

function toolbox() {
  const toolbox = document.querySelector(".c-toolbox");
  if (!toolbox) return;

  const triggers = document.querySelectorAll("[data-toolbox-trigger]");
  const toolboxTrigger = toolbox.querySelector(".c-toolbox__trigger");
  const modal = toolbox.querySelector(".c-toolbox__modal");
  const close = toolbox.querySelector(".c-toolbox__close");
  const scrollTop = document.querySelector(".scroll-top");

  function handleModal() {
    toolboxTrigger.setAttribute(
      "aria-expanded",
      toolboxTrigger.getAttribute("aria-expanded") === "true" ? "false" : "true"
    );
    modal.setAttribute(
      "aria-hidden",
      modal.getAttribute("aria-hidden") === "true" ? "false" : "true"
    );
    scrollTop.setAttribute(
      "aria-hidden",
      modal.getAttribute("aria-hidden") === "true" ? "false" : "true"
    );
  }

  triggers.forEach((trigger) => {
    trigger.addEventListener("click", handleModal);
  });
  close.addEventListener("click", handleModal);
}
toolbox();

/**
 * Fancybox
 */

const initFancybox = () => {
  Fancybox.bind("[data-fancybox]", {
    Thumbs: {
      showOnStart: true,
    },
    Toolbar: {
      display: {
        left: [],
        middle: ["prev", "infobar", "next"],
        right: ["thumbs", "close"],
      },
    },
  });
};
initFancybox();
